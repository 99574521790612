/* Home */
.topBarLogo {
  width: 96px;
  float: left;
  margin-left: 68px;
  margin-top: 16px;
}
.homeTopbar {
  position: absolute;
  height: 60px;
  width: 100%;
  top: 0px;
  border-bottom: 1px solid #E8E8E8;
}

.homeTopRight {
  float: right;
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.homeTopRight li {
  display: inline-block;
  height: 40px;
  margin-left: 16px;
}

.homeTopRight li a {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #2F54EB;
  border: 1px solid #2F54EB;
  box-sizing: border-box;
  border-radius: 4px;
  float: left;
}

.homeLinkGhost {
  background-color: #fff!important;
  border: none!important;
  color: #8C8C8C!important;
}

.homeAbout {
  margin-top: 60px;
  margin-left: 76px!important;
  margin-right: 76px!important;
  width: 100%;
  float: left;
  min-height: 350px;
  background-color: #fff;
}

.homeContactLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
}

.homeContactConfirm {
  float: right;
  color: #fff!important;
  border: 1px solid #2F54EB!important;
  background-color: #2F54EB!important;
}

.contactMap {
  max-width: 100%;
  float: left;
  margin-left: 66px;
  margin-top: 95px;
}

.homeContactForm {
  margin-top: 64px;
}

.homeContactForm h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 40px;
  line-height: 32px;
  color: #2F54EB;
}

.homeFooter {
  margin-top: 223px;
  width: 100%;
  float: left;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 32px;
  padding-right: 32px;
  border-top: 1px solid #EBEBEB;
  height: 80px;
}

.footerLogo {
  width: 121px;
  float: left;
}

.homeCopyright {
  margin-left: 16px;
  color: #8C8C8C;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
  float: left;
}

.footerSocial {
  list-style-type: none;
  float: right;
  margin: 0;
  padding: 0;
}

.footerSocial li {
  float: left;
  width: 48px;
  height: 48px;
}

.footerSocial li i {
  font-size: 24px;
  line-height: 48px;
  color: #60698E;
}
