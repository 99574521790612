.App {
  background: #fafafa;
}

.ant-layout {
  background: #fafafa; 
}
::-webkit-scrollbar{
  display: none;
}

.showMenu {
  position: absolute;
  width: 200px;
  z-index: 9999;
  height: auto;
  top: 60px;
  left: 48px;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.06);
  border-radius: 8px;  
}

.menuArrow {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 92px;
  top: -6px;
  background: #ffffff;
  border-top: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
  transform: rotate(-45deg);
}

.menuDivider {
  background-color: #E8E8E8;
  width: 100%;
  height: 1px;
  margin-bottom: 15px;
}

.menuList {
  list-style-type: none;
  padding: 15px;
}

.menuList li {
  margin-bottom: 15px;
}

.menuList li a {
  font-family: 'SF Pro Display', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #2F54EB;
}

.sideMenu {
  margin-left: 9px;
  margin-right: 9px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ant-layout-sider-trigger {
  background-color: #ffffff;
  border: 1px solid #E8E8E8;
  color:#595959; 
  float: right;
  width: 36px!important;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
